<template>
  <v-container>
    <head-title
      text="Registrar Nuevo Pago"
      :to="{ name: 'accounting-payment-list' }"
    ></head-title>
    <v-form ref="myForm" v-model="valid" :lazy-validation="false">
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            label="Tipo de Compra"
            :items="payment_type"
            item-text="name"
            item-value="value"
            v-model="type"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" v-if="type != 'CJCH'">
          <provider-field v-model="providerId" required :reset="cleaned"></provider-field>
        </v-col>
        <v-col cols="12" md="3">
          <company-name
            v-model="company"
            :reset="cleaned"
            @value="(val) => (company = val)"
            :returnObj="true"
            required
          ></company-name>
        </v-col>
        <v-col
          cols="12"
          md="1"
          v-if="(payment.payment_type != 'N/A') & (payment.payment_type != 'VOUCHER')"
        >
          <v-btn class="mx-2" fab dark small color="primary" @click="getOrders()">
            <v-icon dark>mdi-database-search</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="1"
          v-if="(payment.payment_type == 'VOUCHER') & (payment.payment_type != 'N/A')"
        >
          <v-btn class="mx-2" fab dark small color="primary" @click="showSelect()">
            <v-icon dark>mdi-database-search</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-if="payment.payment_type == 'INVOICE' || payment.payment_type == 'VOUCHER'"
        >
          <v-card class="mx-auto" v-if="showCard">
            <v-container>
              <v-row align="center">
                <v-col cols="12" md="10">
                  <invoice-field
                    v-model="invoiceId"
                    :providerId="payment.provider_id"
                    :businessId="payment.company_id"
                    :type="payment.payment_type"
                    :exclude="excludeInvoices"
                    :reload="load"
                  ></invoice-field>
                </v-col>
                <v-col cols="12" md="1">
                  <v-icon large class="mr-2" color="primary" dark @click="newInvoice()"
                    >mdi-plus-circle</v-icon
                  >
                </v-col>
                <v-col cols="12" md="1">
                  <v-btn class="mx-2" fab dark small color="primary" @click="reload()">
                    <v-icon dark>mdi-refresh</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-divider></v-divider>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th class="text-left">Factura</th>
                        <th class="text-left">Proveedor</th>
                        <th class="text-left">Retención ISR</th>
                        <th class="text-left">Retención IVA</th>
                        <th class="text-left">Exento</th>
                        <th class="text-left">Pendiente</th>
                        <th class="text-left">Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ invoiceId.serie }}-{{ invoiceId.invoice_number }}</td>
                        <td>{{ invoiceId.provider.name }}</td>
                        <td>{{ invoiceId.isr }}</td>
                        <td>{{ invoiceId.iva }}</td>
                        <td>{{ invoiceId.exempt }}</td>
                        <td>
                          {{
                            calculateTax(invoiceId.isr, invoiceId.iva, invoiceId.balance)
                          }}
                        </td>
                        <td>{{ invoiceId.description }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-btn color="primary" @click="addItem(invoiceId.id)">Agregar</v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-if="payment.payment_type == 'INVOICE' || payment.payment_type == 'VOUCHER'"
        >
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="selected"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            required
          >
            <template
              v-if="
                payment.payment_type == 'INVOICE' || payment.payment_type == 'VOUCHER'
              "
              v-slot:[`item.balance`]="{ item }"
            >
              {{ calculateTax(item.isr, item.iva, item.balance) }}
            </template>
            <template
              v-if="
                payment.payment_type == 'INVOICE' || payment.payment_type == 'VOUCHER'
              "
              v-slot:[`item.amount`]="{ item }"
            >
              <v-text-field
                type="number"
                step=".01"
                v-model="item.amount"
                :rules="numRule"
              ></v-text-field>
            </template> </v-data-table
          ><br />
          <v-row>
            <v-col cols="12" md="9">
              <p align="right"><b>Total</b></p>
            </v-col>
            <v-col cols="12" md="2">
              <span>{{ payment.amount }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" v-if="payment.payment_type == 'N/A'">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="selected"
            class="elevation-1"
            hide-default-footer
            required
            disable-pagination
          >
            <template v-slot:[`item.description`]="{ item }">
              <v-text-field type="text" v-model="item.description"></v-text-field>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <v-text-field
                type="number"
                step=".01"
                v-model="item.amount"
                :rules="numRule"
              ></v-text-field>
            </template> </v-data-table
          ><br />
          <v-row>
            <v-col cols="12" md="9">
              <p align="right"><b>Total</b></p>
            </v-col>
            <v-col cols="12" md="2">
              <span>{{ payment.amount }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn color="success" elevation="4" @click="save()" :disabled="!valid"
          >Guardar</v-btn
        >
      </v-row>
    </v-form>
    <payment-detail
      :dialog="detailDialog"
      :paymentId="paymentId"
      v-if="detailDialog"
      @close="$router.push('/accountings/list-payment-order')"
    ></payment-detail>
    <new-invoice
      v-if="businessId != undefined"
      :dialog="invoiceDialog"
      :provider="provider_id"
      :business="businessId"
      :credit="cre"
      @close="invoiceDialog = false"
      @saved="reload()"
    ></new-invoice>
  </v-container>
</template>
<script>
import request from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    CompanyName: () => import("@/components/fields/CompanyField"),
    PaymentDetail: () => import("@/components/accounting/DetailPayment"),
    InvoiceField: () => import("@/components/fields/InvoiceField"),
    newInvoice: () => import("@/components/accounting/invoice/CreateInvoice"),
  },
  data: () => ({
    cre: "INVOICE",
    type: "INVOICE",
    provider_id: 0,
    load: false,
    invoiceDialog: false,
    docType: undefined,
    showCard: false,
    cleaned: false,
    resetAccount: false,
    detailDialog: false,
    valid: false,
    validAmount: false,
    balance: 0,
    excludeInvoices: [],
    invoiceId: {
      provider: {
        id: 0,
        name: "",
      },
    },
    paymentId: 0,
    company: undefined,
    businessId: undefined,
    providerId: undefined,
    payment: {
      payment_type: "INVOICE",
      document_code: "",
      provider_id: undefined,
      company_id: undefined,
      account_id: undefined,
      deposit_slip: "",
      is_confirmed: false,
      deleted: false,
      amount: 0,
    },
    paymentdetail: [],
    selected: [],
    orders: [],
    invoices: {},
    payment_type: [
      { name: "Contado/Crédito", value: "INVOICE" },
      { name: "Contravale", value: "VOUCHER" },
      { name: "Sin Documento", value: "N/A" },
      { name: "Caja Chica", value: "CJCH" },
    ],
    headers: [
      { text: "Factura", value: "code" },
      { text: "Descripción", value: "description" },
      { text: "Retención ISR", value: "isr" },
      { text: "Retención IVA", value: "iva" },
      { text: "Exento", value: "exempt" },
      { text: "Pendiente", value: "balance" },
      { text: "Pago", value: "amount" },
      { text: "", value: "actions" },
    ],
    documents: {
      code: "N/A",
      description: "",
      amount: "",
    },

    //rules
    textRules: [rules.minLength(5)],
    numRule: [rules.minValue(0.01)],
  }),
  watch: {
    selected: {
      handler: function (val) {
        let total = 0;
        this.payment.amount = 0;
        if (
          (this.payment.payment_type != "VOUCHER") &
          (this.payment.payment_type != "INVOICE")
        ) {
          val.forEach((element) => {
            total =
              this.isNum(parseFloat(total, 2)) +
              this.isNum(parseFloat(element.amount, 2));
          });
        } else if (
          this.payment.payment_type == "VOUCHER" ||
          this.payment.payment_type == "INVOICE"
        ) {
          val.forEach((element) => {
            let withouttax = 0;
            if (element.amount && element.amount != 0) {
              withouttax = this.calculateTax(element.isr, element.iva, element.balance);

              if (parseFloat(element.amount, 2) <= parseFloat(withouttax, 2)) {
                this.validAmount = true;
                if (this.$refs.myForm.validate()) {
                  this.valid = true;
                }
                total =
                  this.isNum(parseFloat(total, 2)) +
                  this.isNum(parseFloat(element.amount, 2));
              } else {
                this.$toasted.global.info({
                  message: "Monto mayor al total de la factura",
                });
                this.validAmount = false;
                this.valid = false;
              }
            }
          });
        }
        this.payment.amount = Number(total).toFixed(2);
      },
      deep: true,
    },
    type: function () {
      var hlength = this.headers.length;
      if (this.type == "INVOICE" || this.type == "VOUCHER") {
        this.headers.splice(
          0,
          hlength,
          { text: "Factura", value: "code" },
          { text: "Descripción", value: "description" },
          { text: "Retención ISR", value: "isr" },
          { text: "Retención IVA", value: "iva" },
          { text: "Exento", value: "exempt" },
          { text: "Pendiente", value: "balance" },
          { text: "Pago", value: "amount" },
          { text: "", value: "actions" }
        );
        if (this.type == "INVOICE") {
          this.payment.payment_type = "INVOICE";
          this.docType = "factura";
        } else if (this.type == "VOUCHER") {
          this.payment.payment_type = "VOUCHER";
          this.docType = "voucher";
        }
        this.showSelect();
        this.selected = [];
        this.orders = [];
      } else if (this.type != "VOUCHER" || this.type != "INVOICE") {
        if (this.type == "N/A") {
          this.headers.splice(
            0,
            hlength,
            { text: "Descripción", value: "description" },
            { text: "Total", value: "amount" }
          );
          this.payment.payment_type = "N/A";
        } else if (this.type == "CJCH") {
          this.payment.payment_type = this.type;
        }
        this.showCard = false;
        this.orders = [];
        this.selected = [];
        if (this.type == "N/A") {
          this.selected.push(this.documents);
        }
        this.clean();
      }
      this.showSelect();
    },
    company: function () {
      if (this.company != undefined) {
        if (this.type != "N/A") {
          this.selected = [];
        }
        this.orders = [];
        this.businessId = this.company.id;
      }
    },
    businessId: function () {
      if (this.type != "N/A") {
        this.selected = [];
        this.orders = [];
      }
      if (this.type == "INVOICE" || this.type == "VOUCHER") {
        this.clean();
      }
      if (this.businessId != undefined) {
        this.payment.company_id = this.businessId;
      }
      if (
        this.payment.provider_id != undefined &&
        this.businessId != undefined &&
        this.payment.payment_type == "VOUCHER"
      ) {
        this.getOrders();
        if (this.$route.query.order != undefined && this.providerId !== undefined) {
          this.newInvoice();
        }
      }

      if (this.businessId != undefined && this.providerId != undefined) {
        this.showSelect();
      }
    },
    providerId: function (val) {
      if (this.type != "N/A") {
        this.selected = [];
        this.orders = [];
      }
      if (this.type == "VOUCHER" || this.type == "INVOICE") {
        this.clean();
      }
      if (val != undefined && typeof val === "object") {
        this.payment.provider_id = val.id;
      }
      if (val != undefined && typeof val === "number") {
        this.payment.provider_id = val;
      }
      if (this.businessId != undefined && this.providerId != undefined) {
        this.showSelect();
      }
      if (this.payment.payment_type == "VOUCHER") {
        if (val != undefined && typeof val === "number") {
          this.payment.provider_id = val;
        }
        if (this.businessId != undefined) {
          this.getOrders();
          if (this.$route.query.order != undefined && this.providerId !== undefined) {
            if (this.businessId != undefined) {
              this.showSelect();
            }
            this.newInvoice();
          }
        }
      }
    },
  },
  methods: {
    isNum(val) {
      let value;
      if (isNaN(val)) {
        value = 0;
      } else {
        value = val;
      }
      return value;
    },
    calculateTax(isr, iva, amount) {
      let total_tax = Number(isr) + Number(iva);
      let result = Number(Number(amount) - Number(total_tax)).toFixed(2);
      result = isNaN(result) ? 0 : result;
      return Number(result).toFixed(2);
    },
    getOrders() {
      if (this.payment.payment_type == undefined) {
        this.$toasted.global.info({
          message: "Seleccione Tipo de Pago",
        });
        return;
      }

      if (this.payment.provider_id == undefined) {
        this.$toasted.global.info({
          message: "Seleccione un Proveedor",
        });
        return;
      }
      if (this.businessId == null || this.businessId == undefined) {
        this.$toasted.global.info({
          message: "Seleccione una Empresa",
        });
        return;
      }
      var uri = "";
      if (this.payment.payment_type == "VOUCHER") {
        uri =
          "api/vouchers/?company=" +
          this.businessId +
          "&provider_id=" +
          this.payment.provider_id;
      }
      request.get(uri).then((res) => {
        if (res.status == 200) {
          this.orders = res.data;
        }
      });
    },
    showSelect() {
      if (
        (this.payment.payment_type == "INVOICE" ||
          this.payment.payment_type == "VOUCHER") &&
        this.providerId != undefined &&
        this.businessId != undefined
      ) {
        this.showCard = true;
      }
    },
    save() {
      //  add orders selected
      if ((this.selected.length > 0 && this.payment.amount > 0) || this.type == "CJCH") {
        if (this.type != "CJCH") {
          this.selected.forEach((el) => {
            this.paymentdetail.push({
              document: el.code,
              amount: el.amount,
              invoice: el.document_id,
              description: el.description,
            });
          });
        }
        this.payment.paymentdetail = this.paymentdetail;
        if (this.$refs.myForm.validate()) {
          request.post("api/accounting/", this.payment).then((res) => {
            if (res.status == 201) {
              this.detailDialog = true;
              this.paymentId = res.data.id;
              this.$toasted.global.info({ message: "Registro Creado" });
              this.$refs.myForm.reset();
              this.selected = [];
              this.payment = {
                payment_type: "INVOICE",
                document_code: "",
                provider_id: undefined,
                company_id: undefined,
                account_id: undefined,
                deposit_slip: "",
                is_confirmed: false,
                deleted: false,
                amount: 0,
              };
              this.providerId = undefined;
              this.paymentdetail = [];
              this.selected = [];
            } else {
              console.log(res.data);
              this.selected = [];
              this.paymentdetail = [];
            }
          });
        } else {
          this.$toasted.global.info({
            message: "Falta Información",
          });
        }
      } else {
        this.$toasted.global.info({
          message: "Falta Información",
        });
      }
    },
    addItem(invoice) {
      if (invoice != undefined) {
        this.invoices = this.selected.filter((element) => element.document_id == invoice);
        if (this.invoices.length > 0) {
          this.$toasted.global.info({
            message: "Esta factura ya ha sido agregada",
          });
        } else if (this.invoices.length == 0) {
          this.selected.push({
            document_id: this.invoiceId.id,
            description: this.invoiceId.description,
            code: this.invoiceId.serie + "-" + this.invoiceId.invoice_number,
            isr: this.invoiceId.isr,
            iva: this.invoiceId.iva,
            exempt: this.invoiceId.exempt,
            balance: this.invoiceId.balance,
            amount: 0,
          });
          this.excludeInvoices.push(this.invoiceId.id);
          this.clean();
        }
      }
    },
    clean() {
      this.invoiceId = {
        provider: {
          id: 0,
          name: "",
        },
      };
    },
    newInvoice() {
      this.invoiceDialog = true;
      this.provider_id = this.payment.provider_id;
      this.cre = this.type;
    },
    reload() {
      this.load = this.load ? false : true;
    },
    // resetField() {
    //   this.cleaned ? false : true;
    // },
    getBusinessName(id, provider_id) {
      request.get("api/catalogs/company/" + id).then((res) => {
        if (res.status == 200) {
          this.company = res.data;
          this.providerId = provider_id;
        }
      });
    },
    removeInvoice(id) {
      let index;
      index = this.selected.findIndex((d) => d.id == id);
      let dlt = this.excludeInvoices.findIndex((d) => d == id);
      this.selected.splice(index, 1);
      this.excludeInvoices.splice(dlt, 1);
    },
  },
  mounted() {
    if (this.$route.query.order != undefined) {
      if (this.$route.query.type == "voucher") {
        request.get("/api/vouchers/" + this.$route.query.order + "/").then((response) => {
          this.type = "VOUCHER";

          this.getBusinessName(response.data.company.id, response.data.provider_id);
        });
      }
    } else {
      this.payment.payment_type = "INVOICE";
    }
  },
};
</script>
